replaceAll = (s, m, r, p) ->
    if s == p or r.indexOf(m) > -1 then s else replaceAll(s.replace(m, r), m, r, s)

#########################################################################
### QuestionnaireLabelsObject
#########################################################################
class QuestionnaireLabelsObject

    constructor: (scale, labels, respondent, organizationName=null) ->
        @scale               = scale
        @corelabels          = {}
        @labels              = labels
        @respondent          = respondent
        @languages           = [
                                {code: 'ar', name: 'Arabic'},
                                {code: 'br', name: 'Brazilian'},
                                {code: 'bg', name: 'Bulgarian'},
                                {code: 'ca', name: 'Catalan'},
                                {code: 'cn', name: 'Chinese'},
                                {code: 'hr', name: 'Croatian'},
                                {code: 'cs', name: 'Czech'},
                                {code: 'da', name: 'Danish'},
                                {code: 'nl', name: 'Dutch'},
                                {code: 'en', name: 'English'},
                                {code: 'fi', name: 'Finnish'},
                                {code: 'fr', name: 'French'},
                                {code: 'fr1', name: 'French1'},
                                {code: 'de', name: 'German'},
                                {code: 'de1', name: 'German1'},
                                {code: 'de2', name: 'German2'},
                                {code: 'hu', name: 'Hungarian'},
                                {code: 'it', name: 'Italian'},
                                {code: 'jp', name: 'Japanese'},
                                {code: 'kr', name: 'Korean'},
                                {code: 'lv', name: 'Latvian'},
                                {code: 'ms', name: 'Malay'},
                                {code: 'no', name: 'Norwegian'},
                                {code: 'pl', name: 'Polish'},
                                {code: 'pt', name: 'Portuguese'},
                                {code: 'ro', name: 'Romanian'},
                                {code: 'ru', name: 'Russian'},
                                {code: 'sk', name: 'Slovak'},
                                {code: 'sl', name: 'Slovenian'},
                                {code: 'es', name: 'Spanish'},
                                {code: 'sv', name: 'Swedish'},
                                {code: 'tw', name: 'Taiwanese'},
                                {code: 'th', name: 'Thai'},
                                {code: 'tr', name: 'Turkish'}
                            ]
        @organizationName    = organizationName


        @corelabels['7ad4905b4543ab4a1637dd23c50e36ce']	= {
                name : 'HEADER'
                nl   :  ''
                en   : ''
                de   : ''
                fr   : ''
                it   : ''
                es   : ''
                ar   : ''
                br   : ''
                ca   : ''
                cn   : ''
                cs   : ''
                da   : ''
                fi   : ''
                hu   : ''
                it   : ''
                jp   : ''
                kr   : ''
                lv   : ''
                ms   : ''
                no   : ''
                pl   : ''
                pt   : ''
                ro   : ''
                ru   : ''
                sk   : ''
                sl   : ''
                sv   : ''
                tw   : ''
                th   : ''
                tr   : ''
                bg   : ''
        }


        @corelabels['126ad83a6c76b6a5f45c0dd3ee780082']	= {
                name : 'FOOTER'
                nl   :  ''
                en   : ''
                de   : ''
                fr   : ''
                it   : ''
                es   : ''
                ar   : ''
                br   : ''
                ca   : ''
                cn   : ''
                cs   : ''
                da   : ''
                fi   : ''
                hu   : ''
                it   : ''
                jp   : ''
                kr   : ''
                lv   : ''
                ms   : ''
                no   : ''
                pl   : ''
                pt   : ''
                ro   : ''
                ru   : ''
                sk   : ''
                sl   : ''
                sv   : ''
                tw   : ''
                th   : ''
                tr   : ''
                bg   : ''
        }

        @corelabels['dc7240ee237b0965e3daae75b2650ce1']	= {
                name : 'BUTTON_FORWARD'
                nl   : 'Verder'
                en   : ''
                de   : ''
                fr   : ''
                it   : ''
                es   : ''
                ar   : ''
                br   : ''
                ca   : ''
                cn   : ''
                cs   : ''
                da   : ''
                fi   : ''
                hu   : ''
                it   : ''
                jp   : ''
                kr   : ''
                lv   : ''
                ms   : ''
                no   : ''
                pl   : ''
                pt   : ''
                ro   : ''
                ru   : ''
                sk   : ''
                sl   : ''
                sv   : ''
                tw   : ''
                th   : ''
                tr   : ''
                bg   : ''
        }

        @corelabels['edf395421fb9de6c75cf037b4f1b2e41']	= {
                name : 'BUTTON_BACKWARD'
                nl   : 'Terug'
                en   : ''
                de   : ''
                fr   : ''
                it   : ''
                es   : ''
                ar   : ''
                br   : ''
                ca   : ''
                cn   : ''
                cs   : ''
                da   : ''
                fi   : ''
                hu   : ''
                it   : ''
                jp   : ''
                kr   : ''
                lv   : ''
                ms   : ''
                no   : ''
                pl   : ''
                pt   : ''
                ro   : ''
                ru   : ''
                sk   : ''
                sl   : ''
                sv   : ''
                tw   : ''
                th   : ''
                tr   : ''
                bg   : ''
        }

        @corelabels['0f58d0a217cf9a9d29a9040ffb6b43c8']	= {
                name : 'BUTTON_END'
                nl   : 'Einde'
                en   : ''
                de   : ''
                fr   : ''
                it   : ''
                es   : ''
                ar   : ''
                br   : ''
                ca   : ''
                cn   : ''
                cs   : ''
                da   : ''
                fi   : ''
                hu   : ''
                it   : ''
                jp   : ''
                kr   : ''
                lv   : ''
                ms   : ''
                no   : ''
                pl   : ''
                pt   : ''
                ro   : ''
                ru   : ''
                sk   : ''
                sl   : ''
                sv   : ''
                tw   : ''
                th   : ''
                tr   : ''
                bg   : ''
        }

        @corelabels['ebdf9721be38d1fc1cd6db8c737d1be0']	= {
                name : 'REQUIRED'
                nl   : 'Deze vraag is verplicht'
                en   : ''
                de   : ''
                fr   : ''
                it   : ''
                es   : ''
                ar   : ''
                br   : ''
                ca   : ''
                cn   : ''
                cs   : ''
                da   : ''
                fi   : ''
                hu   : ''
                it   : ''
                jp   : ''
                kr   : ''
                lv   : ''
                ms   : ''
                no   : ''
                pl   : ''
                pt   : ''
                ro   : ''
                ru   : ''
                sk   : ''
                sl   : ''
                sv   : ''
                tw   : ''
                th   : ''
                tr   : ''
                bg   : ''
        }

        @corelabels['0276a834f7e7ae81c2dae29ab3f33fcc']	= {
                name : 'REQUIREDMINMAX'
                nl   : 'Deze vraag is verplicht (min ##MIN##, max ##MAX## antworten)'
                en   : ''
                de   : ''
                fr   : ''
                it   : ''
                es   : ''
                ar   : ''
                br   : ''
                ca   : ''
                cn   : ''
                cs   : ''
                da   : ''
                fi   : ''
                hu   : ''
                it   : ''
                jp   : ''
                kr   : ''
                lv   : ''
                ms   : ''
                no   : ''
                pl   : ''
                pt   : ''
                ro   : ''
                ru   : ''
                sk   : ''
                sl   : ''
                sv   : ''
                tw   : ''
                th   : ''
                tr   : ''
                bg   : ''
        }


        @corelabels['e693cb41811b52895a28e8187d67e200']	= {
                name : 'THANKYOU'
                nl   : 'Hartelijk dank voor jouw medewerking!'
                en   : ''
                de   : ''
                fr   : ''
                it   : ''
                es   : ''
                ar   : ''
                br   : ''
                ca   : ''
                cn   : ''
                cs   : ''
                da   : ''
                fi   : ''
                hu   : ''
                it   : ''
                jp   : ''
                kr   : ''
                lv   : ''
                ms   : ''
                no   : ''
                pl   : ''
                pt   : ''
                ro   : ''
                ru   : ''
                sk   : ''
                sl   : ''
                sv   : ''
                tw   : ''
                th   : ''
                tr   : ''
                bg   : ''
        }


        @corelabels['3a3ea00cfc35332cedf6e5e9a32e94da']	= {
                name : 'E'
                nl   : 'Tevredenheid'
                en   : 'Satisfaction'
                de   : 'Zufriedenheit'
                fr   : 'Satisfaction'
                it   : ''
                es   : ''
                ar   : ''
                br   : ''
                ca   : ''
                cn   : ''
                cs   : ''
                da   : ''
                fi   : ''
                hu   : ''
                it   : ''
                jp   : ''
                kr   : ''
                lv   : ''
                ms   : ''
                no   : ''
                pl   : ''
                pt   : ''
                ro   : ''
                ru   : ''
                sk   : ''
                sl   : ''
                sv   : ''
                tw   : ''
                th   : ''
                tr   : ''
                bg   : ''
        }


        if @scale == 5
            @corelabels['42777eddcbde74fb7a3705274844174b'] = {
                        name : 'E_1'
                        nl   : 'Zeer goed'
                        en   : 'Very good'
                        de   : 'Sehr gut'
                        fr   : 'Très bon(ne)'
                        it   : 'Ottima'
                        es   : 'Excelente'
                        ar   : ''
                        br   : ''
                        ca   : ''
                        cn   : ''
                        cs   : ''
                        da   : ''
                        fi   : ''
                        hu   : ''
                        it   : ''
                        jp   : ''
                        kr   : ''
                        lv   : ''
                        ms   : ''
                        no   : ''
                        pl   : ''
                        pt   : ''
                        ro   : ''
                        ru   : ''
                        sk   : ''
                        sl   : ''
                        sv   : ''
                        tw   : ''
                        th   : ''
                        tr   : ''
                        bg   : ''
                    }

            @corelabels['eb7237defa500fc18aa73ff48b347c31'] = {
                        name : 'E_2'
                        nl   : 'Goed'
                        en   : 'Good'
                        de   : 'Gut'
                        fr   : 'Bon(ne)'
                        it   : 'Buona'
                        es   : 'Bueno'
                        ar   : ''
                        br   : ''
                        ca   : ''
                        cn   : ''
                        cs   : ''
                        da   : ''
                        fi   : ''
                        hu   : ''
                        it   : ''
                        jp   : ''
                        kr   : ''
                        lv   : ''
                        ms   : ''
                        no   : ''
                        pl   : ''
                        pt   : ''
                        ro   : ''
                        ru   : ''
                        sk   : ''
                        sl   : ''
                        sv   : ''
                        tw   : ''
                        th   : ''
                        tr   : ''
                        bg   : ''
                    }

            @corelabels['c96fbab46c1a297db6467df72bb819c4'] = {
                        name : 'E_3'
                        nl   : 'Redelijk goed'
                        en   : 'Reasonable'
                        de   : 'Einigermaßen gut'
                        fr   : 'Correct(e)'
                        it   : 'Sufficiente'
                        es   : 'Razonable'
                        ar   : ''
                        br   : ''
                        ca   : ''
                        cn   : ''
                        cs   : ''
                        da   : ''
                        fi   : ''
                        hu   : ''
                        it   : ''
                        jp   : ''
                        kr   : ''
                        lv   : ''
                        ms   : ''
                        no   : ''
                        pl   : ''
                        pt   : ''
                        ro   : ''
                        ru   : ''
                        sk   : ''
                        sl   : ''
                        sv   : ''
                        tw   : ''
                        th   : ''
                        tr   : ''
                        bg   : ''
                    }

            @corelabels['8c157c9cf698345635e786f3f21f3a21'] = {
                        name : 'E_4'
                        nl   : 'Slecht'
                        en   : 'Poor'
                        de   : 'Schlecht'
                        fr   : 'Mauvais(e)'
                        it   : 'Scarsa'
                        es   : 'Escaso'
                        ar   : ''
                        br   : ''
                        ca   : ''
                        cn   : ''
                        cs   : ''
                        da   : ''
                        fi   : ''
                        hu   : ''
                        it   : ''
                        jp   : ''
                        kr   : ''
                        lv   : ''
                        ms   : ''
                        no   : ''
                        pl   : ''
                        pt   : ''
                        ro   : ''
                        ru   : ''
                        sk   : ''
                        sl   : ''
                        sv   : ''
                        tw   : ''
                        th   : ''
                        tr   : ''
                        bg   : ''
                    }

            @corelabels['8abcbfb5a6e64d8731fd5cca42965124'] = {
                        name : 'E_5'
                        nl   : 'Zeer slecht'
                        en   : 'Very poor'
                        de   : 'Sehr schlecht'
                        fr   : 'Très mauvais(e)'
                        it   : 'Molto scarsa'
                        es   : 'Muy escaso'
                        ar   : ''
                        br   : ''
                        ca   : ''
                        cn   : ''
                        cs   : ''
                        da   : ''
                        fi   : ''
                        hu   : ''
                        it   : ''
                        jp   : ''
                        kr   : ''
                        lv   : ''
                        ms   : ''
                        no   : ''
                        pl   : ''
                        pt   : ''
                        ro   : ''
                        ru   : ''
                        sk   : ''
                        sl   : ''
                        sv   : ''
                        tw   : ''
                        th   : ''
                        tr   : ''
                        bg   : ''
                    }

            @corelabels['b64d7315a26982dc18d01bd7f6d2c007'] = {
                        name : 'E_6'
                        nl   : 'Geen mening'
                        en   : 'No opinion'
                        de   : 'Keine meinung'
                        fr   : 'Sans opinion'
                        it   : 'Nessuna opinione'
                        es   : 'Sin opinión'
                        ar   : ''
                        br   : ''
                        ca   : ''
                        cn   : ''
                        cs   : ''
                        da   : ''
                        fi   : ''
                        hu   : ''
                        it   : ''
                        jp   : ''
                        kr   : ''
                        lv   : ''
                        ms   : ''
                        no   : ''
                        pl   : ''
                        pt   : ''
                        ro   : ''
                        ru   : ''
                        sk   : ''
                        sl   : ''
                        sv   : ''
                        tw   : ''
                        th   : ''
                        tr   : ''
                        bg   : ''
                    }

        if @scale == 6
            @corelabels['6d7874791eae8203abfe1fd54131783f']	 = {
                        name : 'E_1'
                        nl   : 'Zeer goed'
                        en   : 'Very good'
                        de   : 'Sehr gut'
                        fr   : 'Très bon(ne)'
                        it   : 'Ottima'
                        es   : 'Excelente'
                        ar   : ''
                        br   : ''
                        ca   : ''
                        cn   : ''
                        cs   : ''
                        da   : ''
                        fi   : ''
                        hu   : ''
                        it   : ''
                        jp   : ''
                        kr   : ''
                        lv   : ''
                        ms   : ''
                        no   : ''
                        pl   : ''
                        pt   : ''
                        ro   : ''
                        ru   : ''
                        sk   : ''
                        sl   : ''
                        sv   : ''
                        tw   : ''
                        th   : ''
                        tr   : ''
                        bg   : ''
                    }

            @corelabels['bcf914db9cea019816c5974d27b784d2']	 = {
                        name : 'E_2'
                        nl   : 'Goed'
                        en   : 'Good'
                        de   : 'Gut'
                        fr   : 'Bon(ne)'
                        it   : 'Buona'
                        es   : 'Bien'
                        ar   : ''
                        br   : ''
                        ca   : ''
                        cn   : ''
                        cs   : ''
                        da   : ''
                        fi   : ''
                        hu   : ''
                        it   : ''
                        jp   : ''
                        kr   : ''
                        lv   : ''
                        ms   : ''
                        no   : ''
                        pl   : ''
                        pt   : ''
                        ro   : ''
                        ru   : ''
                        sk   : ''
                        sl   : ''
                        sv   : ''
                        tw   : ''
                        th   : ''
                        tr   : ''
                        bg   : ''
                    }

            @corelabels['d8db7a6743b8a6c537cfd32bd6fed462']	= {
                        name : 'E_3'
                        nl   : 'Redelijk goed'
                        en   : 'Fairly good'
                        de   : 'Eher gut'
                        fr   : 'Satisfaisant(e)'
                        it   : 'Abbastanza buona'
                        es   : 'Satisfactorio'
                        ar   : ''
                        br   : ''
                        ca   : ''
                        cn   : ''
                        cs   : ''
                        da   : ''
                        fi   : ''
                        hu   : ''
                        it   : ''
                        jp   : ''
                        kr   : ''
                        lv   : ''
                        ms   : ''
                        no   : ''
                        pl   : ''
                        pt   : ''
                        ro   : ''
                        ru   : ''
                        sk   : ''
                        sl   : ''
                        sv   : ''
                        tw   : ''
                        th   : ''
                        tr   : ''
                        bg   : ''
                    }

            @corelabels['116d16bb3698feb561227ba38a29a29d']	= {
                        name : 'E_4'
                        nl   : 'Redelijk slecht'
                        en   : 'Fairly poor'
                        de   : 'Eher schlecht'
                        fr   : 'Peu satisfaisant(e)'
                        it   : 'Piuttosto negativa'
                        es   : 'Poco satisfactorio'
                        ar   : ''
                        br   : ''
                        ca   : ''
                        cn   : ''
                        cs   : ''
                        da   : ''
                        fi   : ''
                        hu   : ''
                        it   : ''
                        jp   : ''
                        kr   : ''
                        lv   : ''
                        ms   : ''
                        no   : ''
                        pl   : ''
                        pt   : ''
                        ro   : ''
                        ru   : ''
                        sk   : ''
                        sl   : ''
                        sv   : ''
                        tw   : ''
                        th   : ''
                        tr   : ''
                        bg   : ''
                    }

            @corelabels['a57187870cf4103340655122bb219533']	= {
                        name : 'E_5'
                        nl   : 'Slecht'
                        en   : 'Poor'
                        de   : 'Schlecht'
                        fr   : 'Mauvais(e)'
                        it   : 'Negativa'
                        es   : 'Negativo'
                        ar   : ''
                        br   : ''
                        ca   : ''
                        cn   : ''
                        cs   : ''
                        da   : ''
                        fi   : ''
                        hu   : ''
                        it   : ''
                        jp   : ''
                        kr   : ''
                        lv   : ''
                        ms   : ''
                        no   : ''
                        pl   : ''
                        pt   : ''
                        ro   : ''
                        ru   : ''
                        sk   : ''
                        sl   : ''
                        sv   : ''
                        tw   : ''
                        th   : ''
                        tr   : ''
                        bg   : ''
                    }

            @corelabels['9758c6a934f822e1bec528f67082ac2b']	= {
                        name : 'E_6'
                        nl   : 'Zeer slecht'
                        en   : 'Very poor'
                        de   : 'Sehr schlecht'
                        fr   : 'Très mauvais(e)'
                        it   : 'Decisamente negativa'
                        es   : 'Muy negativo'
                        ar   : ''
                        br   : ''
                        ca   : ''
                        cn   : ''
                        cs   : ''
                        da   : ''
                        fi   : ''
                        hu   : ''
                        it   : ''
                        jp   : ''
                        kr   : ''
                        lv   : ''
                        ms   : ''
                        no   : ''
                        pl   : ''
                        pt   : ''
                        ro   : ''
                        ru   : ''
                        sk   : ''
                        sl   : ''
                        sv   : ''
                        tw   : ''
                        th   : ''
                        tr   : ''
                        bg   : ''
                    }

            @corelabels['d23ca9ecb03eb7daca2dc5c2e8fe9ad5'] = {
                        name : 'E_7'
                        nl   : 'Geen mening'
                        en   : 'No opinion'
                        de   : 'Keine meinung'
                        fr   : 'Sans opinion'
                        it   : 'Nessuna opinione'
                        es   : 'Sin opinión'
                        ar   : ''
                        br   : ''
                        ca   : ''
                        cn   : ''
                        cs   : ''
                        da   : ''
                        fi   : ''
                        hu   : ''
                        it   : ''
                        jp   : ''
                        kr   : ''
                        lv   : ''
                        ms   : ''
                        no   : ''
                        pl   : ''
                        pt   : ''
                        ro   : ''
                        ru   : ''
                        sk   : ''
                        sl   : ''
                        sv   : ''
                        tw   : ''
                        th   : ''
                        tr   : ''
                        bg   : ''
                    }


        if @scale == 10
            @corelabels['4df8fc0b6e923b3b3f02fb6c901da03f']	= {
                        name : 'E_1'
                        nl   : '1'
                        en   : '1'
                        de   : '1'
                        fr   : '1'
                        it   : '1'
                        es   : '1'
                        ar   : ''
                        br   : ''
                        ca   : ''
                        cn   : ''
                        cs   : ''
                        da   : ''
                        fi   : ''
                        hu   : ''
                        it   : ''
                        jp   : ''
                        kr   : ''
                        lv   : ''
                        ms   : ''
                        no   : ''
                        pl   : ''
                        pt   : ''
                        ro   : ''
                        ru   : ''
                        sk   : ''
                        sl   : ''
                        sv   : ''
                        tw   : ''
                        th   : ''
                        tr   : ''
                        bg   : ''
                    }

            @corelabels['31ae67f3380e826a8f909b7b0a881d87']	= {
                        name : 'E_2'
                        nl   : '2'
                        en   : '2'
                        de   : '2'
                        fr   : '2'
                        it   : '2'
                        es   : '2'
                        ar   : ''
                        br   : ''
                        ca   : ''
                        cn   : ''
                        cs   : ''
                        da   : ''
                        fi   : ''
                        hu   : ''
                        it   : ''
                        jp   : ''
                        kr   : ''
                        lv   : ''
                        ms   : ''
                        no   : ''
                        pl   : ''
                        pt   : ''
                        ro   : ''
                        ru   : ''
                        sk   : ''
                        sl   : ''
                        sv   : ''
                        tw   : ''
                        th   : ''
                        tr   : ''
                        bg   : ''
                    }

            @corelabels['7b76d7e83c7e3861879965d5b042d2a4']	= {
                        name : 'E_3'
                        nl   : '3'
                        en   : '3'
                        de   : '3'
                        fr   : '3'
                        it   : '3'
                        es   : '3'
                        ar   : ''
                        br   : ''
                        ca   : ''
                        cn   : ''
                        cs   : ''
                        da   : ''
                        fi   : ''
                        hu   : ''
                        it   : ''
                        jp   : ''
                        kr   : ''
                        lv   : ''
                        ms   : ''
                        no   : ''
                        pl   : ''
                        pt   : ''
                        ro   : ''
                        ru   : ''
                        sk   : ''
                        sl   : ''
                        sv   : ''
                        tw   : ''
                        th   : ''
                        tr   : ''
                        bg   : ''
                    }

            @corelabels['ee6ae93b817e9f27c11a4f174e15d067']	= {
                        name : 'E_4'
                        nl   : '4'
                        en   : '4'
                        de   : '4'
                        fr   : '4'
                        it   : '4'
                        es   : '4'
                        ar   : ''
                        br   : ''
                        ca   : ''
                        cn   : ''
                        cs   : ''
                        da   : ''
                        fi   : ''
                        hu   : ''
                        it   : ''
                        jp   : ''
                        kr   : ''
                        lv   : ''
                        ms   : ''
                        no   : ''
                        pl   : ''
                        pt   : ''
                        ro   : ''
                        ru   : ''
                        sk   : ''
                        sl   : ''
                        sv   : ''
                        tw   : ''
                        th   : ''
                        tr   : ''
                        bg   : ''
                    }

            @corelabels['77f8b61f979762f6b75f3af6be91ef69']	= {
                        name : 'E_5'
                        nl   : '5'
                        en   : '5'
                        de   : '5'
                        fr   : '5'
                        it   : '5'
                        es   : '5'
                        ar   : ''
                        br   : ''
                        ca   : ''
                        cn   : ''
                        cs   : ''
                        da   : ''
                        fi   : ''
                        hu   : ''
                        it   : ''
                        jp   : ''
                        kr   : ''
                        lv   : ''
                        ms   : ''
                        no   : ''
                        pl   : ''
                        pt   : ''
                        ro   : ''
                        ru   : ''
                        sk   : ''
                        sl   : ''
                        sv   : ''
                        tw   : ''
                        th   : ''
                        tr   : ''
                        bg   : ''
                    }

            @corelabels['66b21e5b9e14f4bfdeeccf87350c6665']	= {
                        name : 'E_6'
                        nl   : '6'
                        en   : '6'
                        de   : '6'
                        fr   : '6'
                        it   : '6'
                        es   : '6'
                        ar   : ''
                        br   : ''
                        ca   : ''
                        cn   : ''
                        cs   : ''
                        da   : ''
                        fi   : ''
                        hu   : ''
                        it   : ''
                        jp   : ''
                        kr   : ''
                        lv   : ''
                        ms   : ''
                        no   : ''
                        pl   : ''
                        pt   : ''
                        ro   : ''
                        ru   : ''
                        sk   : ''
                        sl   : ''
                        sv   : ''
                        tw   : ''
                        th   : ''
                        tr   : ''
                        bg   : ''
                    }

            @corelabels['c6a5b772a0206e591b57195c9d07fc76']	= {
                        name : 'E_7'
                        nl   : '7'
                        en   : '7'
                        de   : '7'
                        fr   : '7'
                        it   : '7'
                        es   : '7'
                        ar   : ''
                        br   : ''
                        ca   : ''
                        cn   : ''
                        cs   : ''
                        da   : ''
                        fi   : ''
                        hu   : ''
                        it   : ''
                        jp   : ''
                        kr   : ''
                        lv   : ''
                        ms   : ''
                        no   : ''
                        pl   : ''
                        pt   : ''
                        ro   : ''
                        ru   : ''
                        sk   : ''
                        sl   : ''
                        sv   : ''
                        tw   : ''
                        th   : ''
                        tr   : ''
                        bg   : ''
                    }

            @corelabels['1f20b4fdb87cd45c2e653085be1787cd']	= {
                        name : 'E_8'
                        nl   : '8'
                        en   : '8'
                        de   : '8'
                        fr   : '8'
                        it   : '8'
                        es   : '8'
                        ar   : ''
                        br   : ''
                        ca   : ''
                        cn   : ''
                        cs   : ''
                        da   : ''
                        fi   : ''
                        hu   : ''
                        it   : ''
                        jp   : ''
                        kr   : ''
                        lv   : ''
                        ms   : ''
                        no   : ''
                        pl   : ''
                        pt   : ''
                        ro   : ''
                        ru   : ''
                        sk   : ''
                        sl   : ''
                        sv   : ''
                        tw   : ''
                        th   : ''
                        tr   : ''
                        bg   : ''

                    }

            @corelabels['2f44b8c88fb30ef38e9b07171a84021b']	= {
                        name : 'E_9'
                        nl   : '9'
                        en   : '9'
                        de   : '9'
                        fr   : '9'
                        it   : '9'
                        es   : '9'
                        ar   : ''
                        br   : ''
                        ca   : ''
                        cn   : ''
                        cs   : ''
                        da   : ''
                        fi   : ''
                        hu   : ''
                        it   : ''
                        jp   : ''
                        kr   : ''
                        lv   : ''
                        ms   : ''
                        no   : ''
                        pl   : ''
                        pt   : ''
                        ro   : ''
                        ru   : ''
                        sk   : ''
                        sl   : ''
                        sv   : ''
                        tw   : ''
                        th   : ''
                        tr   : ''
                        bg   : ''
                    }

            @corelabels['b2fcbbc9906c0c85c34e2ade10ba0eaa']	= {
                        name : 'E_10'
                        nl   : '10'
                        en   : '10'
                        de   : '10'
                        fr   : '10'
                        it   : '10'
                        es   : '10'
                        ar   : ''
                        br   : ''
                        ca   : ''
                        cn   : ''
                        cs   : ''
                        da   : ''
                        fi   : ''
                        hu   : ''
                        it   : ''
                        jp   : ''
                        kr   : ''
                        lv   : ''
                        ms   : ''
                        no   : ''
                        pl   : ''
                        pt   : ''
                        ro   : ''
                        ru   : ''
                        sk   : ''
                        sl   : ''
                        sv   : ''
                        tw   : ''
                        th   : ''
                        tr   : ''
                        bg   : ''
                    }

            @corelabels['20a2e0ec4ea9bf8a90e30e6ecb6ed4cf'] = {
                        name : 'E_11'
                        nl   : 'Geen mening'
                        en   : 'No opinion'
                        de   : 'Keine Meinung'
                        fr   : 'Sans opinion'
                        it   : 'Nessuna opinione'
                        es   : 'Sin opinión'
                        ar   : ''
                        br   : ''
                        ca   : ''
                        cn   : ''
                        cs   : ''
                        da   : ''
                        fi   : ''
                        hu   : ''
                        it   : ''
                        jp   : ''
                        kr   : ''
                        lv   : ''
                        ms   : ''
                        no   : ''
                        pl   : ''
                        pt   : ''
                        ro   : ''
                        ru   : ''
                        sk   : ''
                        sl   : ''
                        sv   : ''
                        tw   : ''
                        th   : ''
                        tr   : ''
                        bg   : ''
                    }



        @corelabels['dd7536794b63bf90eccfd37f9b147d7f']	= {
                    name : 'I'
                    nl   : 'Belang'
                    en   : 'Importance'
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['a3686747d7705aad1baeec00e8898bc5']	= {
                    name : 'I_1'
                    nl   : 'Zeer belangrijk'
                    en   : 'Very important'
                    de   : 'Sehr wichtig'
                    fr   : ''
                    it   : 'Molto importante'
                    es   : 'Muy importante'
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''

                }

        @corelabels['2a000f708d9c68124181b57b24579703']	= {
                    name : 'I_2'
                    nl   : 'Belangrijk'
                    en   : 'Important'
                    de   : 'Wichtig'
                    fr   : ''
                    it   : 'Importante'
                    es   : 'Importante'
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['5068013ead055be4b66e3ecf20c0d515']	= {
                    name : 'I_3'
                    nl   : 'Minder belangrijk'
                    en   : 'Of little importance'
                    de   : 'Weniger wichtig'
                    fr   : ''
                    it   : 'Piuttosto irrilevante'
                    es   : 'Ligeramente insignificante'
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['7d8c8019a301f6385932fb41375503e5']	= {
                    name : 'I_4'
                    nl   : 'Onbelangrijk'
                    en   : 'Unimportant'
                    de   : 'Unwichtig'
                    fr   : ''
                    it   : 'Irrilevante'
                    es   : 'Insignificante'
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['802c84c63bfa7a687a29dddc7f24433c'] = {
                    name : 'I_5'
                    nl   : 'Geen mening'
                    en   : 'No opinion'
                    de   : 'Keine Meinung'
                    fr   : 'Sans opinion'
                    it   : 'Nessuna opinione'
                    es   : 'Sin opinión'
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }


        @corelabels['44c29edb103a2872f519ad0c9a0fdaaa']	= {
                    name : 'P'
                    nl   : 'Concurrentie'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['20868fa29dfc38ac154b8ef762766b41']	= {
                    name : 'P_1'
                    nl   : 'Beter'
                    en   : 'Better'
                    de   : 'Besser'
                    fr   : 'Supérieur(e)'
                    it   : 'Migliore'
                    es   : 'Superior'
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['2d70da379b3ffb56bd104b348ba21c55']	= {
                    name : 'P_2'
                    nl   : 'Gelijk'
                    en   : 'Equal'
                    de   : 'Gsleich gut'
                    fr   : 'équivalent(e)'
                    it   : 'Uguale'
                    es   : 'Equivalente'
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['c0ac8b12bab590e951e0afb93938cdcb']	= {
                    name : 'P_3'
                    nl   : 'Slechter'
                    en   : 'Worse'
                    de   : 'Schlechter'
                    fr   : 'Inférieur(e)'
                    it   : 'Peggiore'
                    es   : 'Peor'
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['d94aa42e38e4ef613c5cb9a398883c3e'] = {
                    name : 'P_4'
                    nl   : 'Geen mening'
                    en   : 'No opinion'
                    de   : 'Keine Meinung'
                    fr   : 'Sans opinion'
                    it   : 'Nessuna opinione'
                    es   : 'Sin opinión'
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['0d61f8370cad1d412f80b84d143e1257']	= {
                    name : 'C'
                    nl   : ''
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['4fa71d007c094ac3c858919aec515277']	= {
                    name : 'C_1'
                    nl   : 'Opmerking'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['2a9ddb3e87c510935888a04e600186e1']	= {
                    name : 'Q_DIVISION_REMAINING'
                    nl   : 'Nog te verdelen'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['f9cdfb7c89dff25d0ccf8f66125fc9b7']	= {
                    name : 'Q_DIVISION_TOTAL'
                    nl   : 'Totaal'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['90f2b0f133c831565a38a3717f5be956']	= {
                    name : 'Q_RATING_HIGH'
                    nl   : 'Zeer waarschijnlijk'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['75d34931978303ede1c21ab06d50e0d5']	= {
                    name : 'Q_RATING_LOW'
                    nl   : 'Zeer onwaarschijnlijk'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['4fe42c11c55d7caf61066a154bbb7906']	= {
                    name : 'LANGUAGE'
                    nl   : 'Language'
                    en   : 'Language'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['bf592a0450f32ecb09500797b80e5892']	= {
                    name : 'LANGUAGE_ARABIC'
                    nl   : 'Arabic'
                    en   : 'Arabic'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['3676a735f7dba931512b32454075f534']	= {
                    name : 'LANGUAGE_BRAZILIAN'
                    nl   : 'Brazilian'
                    en   : 'Brazilian'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['5b491bdc139d659515944d6cfc6a59f9']	= {
                    name : 'LANGUAGE_CATALAN'
                    nl   : 'Catalan'
                    en   : 'Catalan'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['842a70f73bdfde7c234a25ee1d20b775']	= {
                    name : 'LANGUAGE_CHINESE'
                    nl   : 'Chinese'
                    en   : 'Chinese'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['09b77fa6d07c11e88dbb784f438a5ad0']	= {
                    name : 'LANGUAGE_CROATIAN'
                    nl   : 'Croatian'
                    en   : 'Croatian'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }


        @corelabels['12362308d07c11e89069784f438a5ad0']	= {
                    name : 'LANGUAGE_CZECHISH'
                    nl   : 'Czechish'
                    en   : 'Czechish'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }



        @corelabels['8dd2ce521ecfdc5fc879b9e6d256136c']	= {
                    name : 'LANGUAGE_DUTCH'
                    nl   : 'Dutch'
                    en   : 'Dutch'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['837143c9573ed511955d89b303f576e1']	= {
                    name : 'LANGUAGE_ENGLISH'
                    nl   : 'English'
                    en   : 'English'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['7e4948bb124711822a78077dc23dc0b3']	= {
                    name : 'LANGUAGE_FINNISH'
                    nl   : 'Finnish'
                    en   : 'Finnish'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['f67b027b965d88b64403a5afe36794c3']	= {
                    name : 'LANGUAGE_FRENCH'
                    nl   : 'French'
                    en   : 'French'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }


        @corelabels['81e7e49ed09711e8b290784f438a5ad0']	= {
                    name : 'LANGUAGE_FRENCH'
                    nl   : 'French1'
                    en   : 'French1'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }


        @corelabels['81ecf28dca3e8d10f1287e0927c6805c']	= {
                    name : 'LANGUAGE_GERMAN'
                    nl   : 'German'
                    en   : 'German'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }


        @corelabels['f514bddcd09611e88f03784f438a5ad0']	= {
                    name : 'LANGUAGE_GERMAN'
                    nl   : 'German 1'
                    en   : 'German 1'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }


        @corelabels['fbddcf64d09611e882e2784f438a5ad0']	= {
                    name : 'LANGUAGE_GERMAN'
                    nl   : 'German 2'
                    en   : 'German 2'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['c1cb0f34d07b11e89f6a784f438a5ad0']	= {
                    name : 'LANGUAGE_HUNGARIAN'
                    nl   : 'Hungarian'
                    en   : 'Hungarian'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }



        @corelabels['0fa22e7f57e95c0d725dae6318460264']	= {
                    name : 'LANGUAGE_ITALIAN'
                    nl   : 'Italian'
                    en   : 'Italian'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['e4eba66b77fbfd8e287cc8883ee132a8']	= {
                    name : 'LANGUAGE_JAPANESE'
                    nl   : 'Japanese'
                    en   : 'Japanese'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['07ac3d5d7aca01c6b764af2222837bef']	= {
                    name : 'LANGUAGE_KOREAN'
                    nl   : 'Korean'
                    en   : 'Korean'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['d2b67f411d58258c64c636b415034170']	= {
                    name : 'LANGUAGE_MALAY'
                    nl   : 'Malay'
                    en   : 'Malay'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['da42d9647ca7a9efb39ee77816ac7ef7']	= {
                    name : 'LANGUAGE_NORWEGIAN'
                    nl   : 'Norwegian'
                    en   : 'Norwegian'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['af338066c1be5abc22e3c021d47c1951']	= {
                    name : 'LANGUAGE_POLISH'
                    nl   : 'Polish'
                    en   : 'Polish'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['2374da1222f0ea4ae9bf176f8813cb6f']	= {
                    name : 'LANGUAGE_PORTUGUESE'
                    nl   : 'Portuguese'
                    en   : 'Portuguese'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['325abe8452f3f19d4f88a21dd6b0e364']	= {
                    name : 'LANGUAGE_ROMANIAN'
                    nl   : 'Romanian'
                    en   : 'Romanian'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['ddf06c573598c7ae1484e53a027218b4']	= {
                    name : 'LANGUAGE_RUSSIAN'
                    nl   : 'Russian'
                    en   : 'Russian'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['d0b56ffef312b82f755ca922a2e5f2e1']	= {
                    name : 'LANGUAGE_SLOVAK'
                    nl   : 'Slovak'
                    en   : 'Slovak'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['8886cf18749511e9bf47784f438a5ad0']	= {
                    name : 'LANGUAGE_SLOVENIAN'
                    nl   : 'Slovenian'
                    en   : 'Slovenian'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }








        @corelabels['dc38a18c779ed9390e0f2b7038058d33']	= {
                    name : 'LANGUAGE_SPANISH'
                    nl   : 'Spanish'
                    en   : 'Spanish'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['363bf143d005ba4fac46aba82c65ca2d']	= {
                    name : 'LANGUAGE_SWEDISH'
                    nl   : 'Swedish'
                    en   : 'Swedish'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }


        @corelabels['e103ad15fc341317a37500fe72d97cac']	= {
                    name : 'LANGUAGE_TAIWANESE'
                    nl   : 'Taiwanese'
                    en   : 'Taiwanese'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['98aa6f879d6d3eb654c0c73137d07212']	= {
                    name : 'LANGUAGE_THAI'
                    nl   : 'Thai'
                    en   : 'Thai'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['0f3aeb108f67fab4ccb32cb9c90943b9']	= {
                    name : 'LANGUAGE_TURKISH'
                    nl   : 'Turkish'
                    en   : 'Turkish'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }

        @corelabels['1dd77f26ee79dbd2016328d7451bf58b']	= {
                    name : 'LANGUAGE_UKRAINIAN'
                    nl   : 'Ukrainian'
                    en   : 'Ukrainian'
                    en   : ''
                    de   : ''
                    fr   : ''
                    it   : ''
                    es   : ''
                    ar   : ''
                    br   : ''
                    ca   : ''
                    cn   : ''
                    cs   : ''
                    da   : ''
                    fi   : ''
                    hu   : ''
                    it   : ''
                    jp   : ''
                    kr   : ''
                    lv   : ''
                    ms   : ''
                    no   : ''
                    pl   : ''
                    pt   : ''
                    ro   : ''
                    ru   : ''
                    sk   : ''
                    sl   : ''
                    sv   : ''
                    tw   : ''
                    th   : ''
                    tr   : ''
                    bg   : ''
                }


        @corelabels['UNSUBSCRIBE_BUTTON']	= {
                    name : 'UNSUBSCRIBE_BUTTON'
                    nl   : "Afmelden"
                    en   : "Unsubscribe"
                }

        @corelabels['UNSUBSCRIBE_TITLE']	= {
                    name : 'UNSUBSCRIBE_TITLE'
                    nl   : "Wilt u geen uitnodigingen meer ontvangen? Hier kunt u zich afmelden."
                    en   : "Do not want to get invitations anymore? Please unsubscribe."
                }

        @corelabels['UNSUBSCRIBE_FEEDBACK']	= {
                    name : 'UNSUBSCRIBE_FEEDBACK'
                    nl   : "U bent succesvol afgemeld."
                    en   : "You have successfully unsubscribed from this survey."
                }

        @corelabels['UNSUBSCRIBE_REASON_QUESTION']	= {
                    name : 'UNSUBSCRIBE_REASON_QUESTION'
                    nl   : "Wij zouden u willen vragen om uw reden aan te geven waarom u niet wenst deel te nemen aan het onderzoek, wilt u dit voor u houden kies dan het antwoord <i>Wil ik niet zeggen</i>. Deze gegevens zullen niet teruggekoppeld worden aan de opdrachtgever. Uw afmelding wordt verwerkt zodra u op de <b>Afmelden</b> knop klikt."
                    en   : "Would you please tell us why you do not want to participate in this survey? Please click the <b>Unsubscribe</b> button after answering to unsubscribe."
                }

        @corelabels['UNSUBSCRIBE_REASON_NO_TIME']	= {
                    name : 'UNSUBSCRIBE_REASON_NO_TIME'
                    nl   : "Geen tijd"
                    en   : "No time"
                }

        @corelabels['UNSUBSCRIBE_REASON_NO_INTEREST']	= {
                    name : 'UNSUBSCRIBE_REASON_NO_INTEREST'
                    nl   : "Geen interesse"
                    en   : "No interest"
                }

        @corelabels['UNSUBSCRIBE_REASON_SATISFIED']	= {
                    name : 'UNSUBSCRIBE_REASON_SATISFIED'
                    nl   : "Tevreden"
                    en   : "Satisfied"
                }

        @corelabels['UNSUBSCRIBE_REASON_NOT_SATISFIED']	= {
                    name : 'UNSUBSCRIBE_REASON_NOT_SATISFIED'
                    nl   : "Ontevreden"
                    en   : "Not satisfied"
                }

        @corelabels['UNSUBSCRIBE_REASON_NO_EXPERIENCE']	= {
                    name : 'UNSUBSCRIBE_REASON_NO_EXPERIENCE'
                    nl   : "Te weinig ervaring"
                    en   : "No experience"
                }

        @corelabels['UNSUBSCRIBE_REASON_NO_CONTACT']	= {
                    name : 'UNSUBSCRIBE_REASON_NO_CONTACT'
                    nl   : "Geen contact (meer)"
                    en   : "No contact (anymore)"
                }

        @corelabels['UNSUBSCRIBE_REASON_OTHER_NAMELY']	= {
                    name : 'UNSUBSCRIBE_REASON_OTHER_NAMELY'
                    nl   : "Anders, namelijk...."
                    en   : "Other, namely..."
                }

        @corelabels['UNSUBSCRIBE_REASON_KEEP_PRIVATE']	= {
                    name : 'UNSUBSCRIBE_REASON_KEEP_PRIVATE'
                    nl   : "Wil ik niet zeggen"
                    en   : "Do not wish to say"
                }


    getQuestionnaireItemLabelById: (id,language) ->
        label = ''
        if @corelabels.hasOwnProperty(id)
            if @corelabels[id].hasOwnProperty(language)
                label = @corelabels[id][language]
        if @labels.hasOwnProperty(id)
            if @labels[id].hasOwnProperty(language)
                label = @labels[id][language]
        if @respondent and label.indexOf('##') > -1
            for key,value of @respondent
                if typeof(value) == 'string'
                    tag = '##' + key + '##'
                    #label       = label.replace(tag, value)
                    label       = replaceAll(label, tag, value)
        if label.substring(0,3) == "<p>"
            label = label.substring(3 , label.length - 5)

        # replace the text ##ORGANIZATION_NAME## with the organization name if this is a preview
        if @organizationName
          label = label.replaceAll('##ORGANIZATION_NAME##', @organizationName || "")

        return label


    getQuestionnaireItemLabelByName: (name,language) ->
        keyId = ''
        for key, value of @corelabels
            if value['name'] == name
                keyId = key
        return this.getQuestionnaireItemLabelById(keyId,language)
